<template>
  <b-row>
    <b-col md="3">
      <ProductCategorySort :list="items" />
    </b-col>
    <b-col md="9">
      <b-tabs>
        <b-tab title="General">
          <ProductCategoryEdit />
        </b-tab>
        <b-tab title="Category Product">
          <ProductList />
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { BRow, BCol, BTabs, BTab } from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import Config from '@/@config/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ProductCategorySort from './ProductCategorySort.vue'
import ProductCategoryEdit from './ProductCategoryEdit.vue'
import ProductList from './ProductList.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    ProductCategorySort,
    ProductCategoryEdit,
    ProductList,
  },
  directives: {
    Ripple,
  },

  mixins: [general],
  data() {
    return {
      required,
      model: {},
      activeOptions: Config.activeOptions,
      items: [],
    }
  },
  mounted() {
    this.loadList()
    // this.loadDetail()
  },
  methods: {
    // async loadDetail() {
    //   const templateId = JSON.parse(localStorage.getItem('templateID')).id
    //   try {
    //     const res = await Request.get(
    //       this.$http,
    //       `${process.env.VUE_APP_API_URL}/platform_category/${
    //         this.$route.params.id.split('-')[0]
    //       }?template_id=${templateId}`,
    //     )
    //     if (res.status === 200) {
    //       if (res.data.status) {
    //         this.model = res.data.data
    //         if (res.data.data.is_active === 1) {
    //           this.model.is_active = true
    //         } else {
    //           this.model.is_active = false
    //         }
    //       } else {
    //         this.$toast({
    //           component: ToastificationContent,
    //           position: 'top-right',
    //           props: {
    //             title: 'Login fail',
    //             icon: 'AlertOctagonIcon',
    //             variant: 'danger',
    //             text: String(this.showError(res.data.error, ',')),
    //           },
    //         })
    //       }
    //     }
    //   } catch (error) {
    //     this.$toast({
    //       component: ToastificationContent,
    //       position: 'top-right',
    //       props: {
    //         title: 'Opps! Something wrong',
    //         icon: 'AlertOctagonIcon',
    //         variant: 'danger',
    //         text: String(error),
    //       },
    //     })
    //   }
    // },
    async loadList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_category/tree?template_id=${templateId}&limit=1000`,
        )
        if (result.status === 200) {
          const res = result.data.data
          // this.perPage = res.count_page
          // this.currentPage = res.current_page
          // this.totalRows = res.items.length
          this.items = res
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      const params = {
        template_id: JSON.parse(localStorage.getItem('templateID')).id,
        name: this.model.name,
        // position: Number(this.model.position),
        is_active: Number(this.model.is_active),
        slug: this.model.slug,
        // meta_description: this.model.meta_description,
        // meta_keyword: this.model.meta_keyword,
        content: this.model.content,
      }
      if (this.model.avatar) {
        params.avatar = this.model.avatar
      }
      if (this.model.parent_id !== null) {
        params.parent_id = this.model.parent_id
      } else {
        params.parent_id = '0'
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const res = await Request.put(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_category/${this.$route.params.id}`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Update Category success',
                  },
                })
                this.$router.push('/product_category/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
        // this.model.avatar = e.target.result.replace(
        //   'data:image/jpeg;base64,',
        //   '',
        // )
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
